import React from "react"
//import { Link } from "gatsby"

export default function Standings({ standings }) {
    standings.forEach((run, index) => {
        run.class = "standing"
        if(run.Video)
            run.class += " pointer";

        switch (run.Rank) {
            case 1:
                run.class += " first";
                break;
            case 2:
                run.class += " second";
                break;
            case 3:
                run.class += " third";
                break;
        }
    });


    return (
        <main>
            {standings.map((run) => (
                <div key={run.Rank} className={run.class} onClick={() => { if(run.Video){ window.open(run.Video); } }}>
                    <div className="rank">
                        {run.Rank}
                    </div>
                    <div className="runner">
                        {run.SrcName}
                    </div>
                    <div className="time">
                        {run.InGameTime}
                    </div>
                </div>
            ))}
        </main>
    )
}