import * as React from "react"
import "../styles/main.scss"
import Layout from "../components/layout.js"
import Standings from "../components/standings.js"

const seedList = [
    {
        name: "N1234567890",
        seed: "1234567890"
    },
    {
        name: "N-123123123",
        seed: "-123123123"
    },
    {
        name: "N777777777",
        seed: "777777777"
    },
    {
        name: "N420696666",
        seed: "420696666"
    }
]

// markup
const IndexPage = () => {
    const [seedList, setSeedList] = React.useState([]);
    const [selectedSeed, setSelectedSeed] = React.useState({});
    const [standings, setStandings] = React.useState([]);
    const [showSelectMenu, setShowSelectMenu] = React.useState(false);

    React.useEffect(() => {
        fetch(`https://us-central1-seedcycle-4bbf4.cloudfunctions.net/leaderboards`)
            .then(response => response.json())
            .then(data => {
                setSeedList(data, []);
                setSelectedSeed(data[0], []);
                getStandings(data[0]["Seed"]);
            });
    }, []);

    function getStandings(seed) {
        setShowSelectMenu(false);
        fetch(`https://us-central1-seedcycle-4bbf4.cloudfunctions.net/runs?seed=${seed}`)
            .then(response => response.json())
            .then(innerData => {
                var hasResults = innerData.response != "No Results";
                setStandings(hasResults ? innerData : [], []);
            });
    }

    return (
        <main>
            <title>Seed Cycle - Leaderboard</title>
            <Layout></Layout>

            <div className="seed-summary" >
                <div className="select-list">
                    <label className={showSelectMenu ? 'active' : ''} onClick={() => setShowSelectMenu(!showSelectMenu)}>{selectedSeed.Name}: {selectedSeed.Seed}</label>
                    <ul>
                        {seedList.map((s) => (
                            <li key={s.Seed} onClick={() => { setSelectedSeed(s); getStandings(s.Seed); }}>{s.Name}: {s.Seed}</li>
                        ))}
                    </ul>
                </div>
                <div className="closing">
                    Closing Date/Time {new Date(selectedSeed.EndTimestamp * 1000).toLocaleString()}
                </div>
            </div>

            <Standings standings={standings}></Standings>


            {/*
            <div className={`standing`}>
                <div className="rank">
                    ...
                </div>
                <div className="runner">
                    No runs yet
                </div>
                <div className="time">
                    ...
                </div>
            </div>*/}

            <p><i>Runs will be updated periodically, all videos will be hidden until the end of the cycle. <br />Closing time is always 11:59:59PM GMT</i></p>
        </main>
    )
}

export default IndexPage
